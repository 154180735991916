import React from "react";

export default function EducationImg(props) {

  const theme = props.theme;

  return (

    <svg 
       data-name="Layer 1" width="571.9401" 
       height="689.03807" 
       viewBox="0 0 571.9401 689.03807" 
       >
       
       <path 
       d="M310.01916,435.42226a27.98147,27.98147,0,0,0,42.37447,6.73419L438.65036,491.628,435.4356,440.0556l-81.418-39.07245a28.13311,
       28.13311,0,0,0-43.99844,34.43911Z" 
       transform="translate(-305.93035 -105.86477)" 
       fill={theme.skinColor}/>
       
       <path 
       d="M559.74073,633.60374c-25.58649,35.81171-24.67114,95.72552-17.53253,161.29907l120.97431,0,5.25976-12.27277,
       7.013,12.27277H789.41664s-8.76624-166.5589-24.54554-171.81867S559.74073,633.60374,559.74073,633.60374Z" 
       transform="translate(-305.93035 -105.86477)" fill="#2f2e41"/>
       
       <circle cx="357.25219" cy="92.2184" r="61.36378" fill={theme.skinColor}/>
       
       <path d="M707.534,275.36772l12,23,83,37L780.71663,546.50355,771.534,568.36772l4.96133,18.98719L764.534,601.36772c12.00666,
       10.86383,13.53168,22.23645,7,34l-38.22143,8.75551s-212.52143,18.89787-194.6109-14.026c19.5697-35.97382,
       24.5531-142.21509-18.64893-198.988-36.67743-48.19873-8.51874-110.74154-8.51874-110.74154l87-26,32-19Z" 
       transform="translate(-305.93035 -105.86477)" fill={theme.imageClothes}/>
       
       <path d="M690.42555,108.19489a3.3845,3.3845,0,0,1,3.25677-1.93272c-28.09344-1.68047-57.68463,1.5939-81.18768,17.0753-5.694,
       3.75065-11.06234,8.2243-17.25845,10.94508a1.85911,1.85911,0,0,0-.37964,3.163l.68052.53368a1.86606,1.86606,0,0,1-.60741,
       3.25335l0,0a1.86606,1.86606,0,0,0-.4688,3.35219l5.21528,3.37072a1.85778,1.85778,0,0,1,.0171,3.12575,9.27094,9.27094,0,0,1-1.38684.70319,
       1.86177,1.86177,0,0,0-.59,3.12753,18.53712,18.53712,0,0,1,5.04588,7.37659c1.73828,4.51977,2.05054,9.44217,2.34418,14.27575,1.79657-6.9743,
       9.408-11.07971,16.59783-11.49687s14.20191,1.95513,21.1991,3.66045a117.27165,117.27165,0,0,0,46.548,1.79065c-5.25647,10.54876-.82245,
       23.13119,3.63952,34.03981l14.44311-6.19059c19.83576-11.1969,6.01886,36.16774-1.39679,42.67007,15.14148,5.24939,54.77246-53.01373,
       42.02326-75.11433-6.632-11.49641-1.92432-26.324-14.96289-37.2275-6.39032-6.72715-19.14893-3.73718-25.66358-9.32824-2.56408-2.20055,
       1.34412-8.08781-2.17956-9.37084C704.184,109.57076,690.61909,107.76539,690.42555,108.19489Z" transform="translate(-305.93035 -105.86477)" 
       fill={theme.dark}/>
       
       <path d="M586.484,300.30766c-11.26,49.99-40.14,166.71-69.44,191.12011a17.09185,17.09185,0,0,1-6.52,3.84986c-102.99,
       25.09009-135.99-38.90991-135.99-38.90991s27.6-13.45,28.3-39.74l55.66,17.27,48.52-109.19007,7.14-6.66.04-.03992Z" 
       transform="translate(-305.93035 -105.86477)" fill="#3f3d56"/>
       
       <path d="M806.07542,761.94066a27.9815,27.9815,0,0,0-2.93831-42.80551L832.009,623.98224l-49.53858,14.69627L762.648,726.78424a28.13311,
       28.13311,0,0,0,43.42743,35.15642Z" transform="translate(-305.93035 -105.86477)" fill={theme.skinColor}/>
       
       <path d="M779.6504,330.0445l22.8836,5.32322s94.58417,184.02791,71.79194,219.09292-50.7146,151.15612-50.7146,151.15612-41.298-8.21-57.07734-29.249l28.89558-137.68626-29.8053-115.7146Z" transform="translate(-305.93035 -105.86477)" fill={theme.imageClothes}/>
       
       <path d="M345.50989,603.32045l.82172-296.38866a22.65241,22.65241,0,0,1,22.68944-22.564l223.83925.62059a22.65238,22.65238,0,0,1,22.56385,22.68944l-.82172,296.38866a22.65238,22.65238,0,0,1-22.68932,22.564l-223.83924-.62058A22.6524,22.6524,0,0,1,345.50989,603.32045Z" transform="translate(-305.93035 -105.86477)" fill="#e6e6e6"/>
       
       <path d="M362.92794,513.84626l.52828-190.54552a21.21423,21.21423,0,0,1,21.24934-21.13184l192.371.53334a21.21515,21.21515,0,0,1,21.13292,21.24935l-.73122,263.74558A21.21517,21.21517,0,0,1,576.22782,608.829L457.058,608.49863A94.49829,94.49829,0,0,1,362.92794,513.84626Z" transform="translate(-305.93035 -105.86477)" fill="#fff"/>
       
       <path d="M555.034,385.86772h-150a9,9,0,0,1,0-18h150a9,9,0,0,1,0,18Z" transform="translate(-305.93035 -105.86477)" fill={theme.accentColor}/>
       
       <path d="M438.034,344.36772h-33a9,9,0,0,1,0-18h33a9,9,0,0,1,0,18Z" transform="translate(-305.93035 -105.86477)" fill={theme.accentColor}/>
       
       <path d="M555.034,427.36772h-150a9,9,0,0,1,0-18h150a9,9,0,0,1,0,18Z" transform="translate(-305.93035 -105.86477)" fill={theme.accentColor}/>
       
       <path d="M555.034,468.86772h-150a9,9,0,0,1,0-18h150a9,9,0,0,1,0,18Z" transform="translate(-305.93035 -105.86477)" fill={theme.accentColor}/>
       
       <circle cx="236.96655" cy="436.54264" r="35.81102" fill={theme.accentColor}/>
       
       <path d="M538.01154,561.04537a3.98231,3.98231,0,0,1-3.18646-1.59372l-9.7698-13.02661a3.98339,3.98339,0,1,1,6.37358-4.77985l6.39173,8.52166,16.41634-24.62419a3.98356,3.98356,0,1,1,6.629,4.41936L541.32638,559.2714a3.9851,3.9851,0,0,1-3.204,1.77267C538.08546,561.04472,538.0485,561.04537,538.01154,561.04537Z" transform="translate(-305.93035 -105.86477)" fill="#fff"/>
       
       <circle cx="160.60365" cy="92.50295" r="17" fill="#f2f2f2"/>
       
       <circle cx="98.60365" cy="581.50295" r="17" fill="#f2f2f2"/>
       
       <circle cx="489.60365" cy="158.50295" r="17" fill="#f2f2f2"/></svg>
  );
}
